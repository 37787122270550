import ADCImage01 from "./project_images/ADC_Image_01.jpg"
import DCSImage01 from "./project_images/DCS_Image_01.jpg"
import cs50Image01 from "./project_images/CS50_Image_01.jpg"
import websiteImage01 from "./project_images/website_image_01.png"

const about = {
    // all the properties are optional - can be left empty or deleted
    title: 'Samuel Graham',
    role: 'Personal Projects',
    description:
        'A description of personal projects which I have worked on.\nThese include programs developed in my free time to automate tasks, beat coding challenegs, or simply improve my programming skills.',
    resume: './resume',
    social: {
        linkedin: 'https://www.linkedin.com/in/samuel-graham-uob/',
        github: 'https://github.com/sg9372',
    },
    }

const projects = [
    {
        id: 1,
        title: "Anki Deck Creator (ADC)",
        description: "After finishing both my Software Engineering summer internship, I set out to build an app to help myself and others learn new languages.\n\nThe 'Anki Deck Creator' (ADC) takes any PDF in Spanish, for example a book, and; detects each word, lemmatizes all verbs, obtains the frequency of each word, and stores the data in an SQL database. Then, each word is translated, and an Anki cue card deck is created. The deck contains each unique PDF word, in order of frequency.\n\nThis project was chosen by me, to learn about data handling, SQL databases, and implementing online API's, while creating a program to assist me with my language learning journey.",
        image: ADCImage01,
        githubLink: "https://github.com/sg9372/ADC",
    },
    {
         id: 2,
         title: "The Code Book Ciphers (CBC)",
         description: "After finishing 'The Code Book' by Simon Singh, I successfully solved the first four cipher challenges from the book. This involved solving Simple Monoalphabetic Substitution Ciphers with and without Homophones, Caesar Shift Ciphers and the Vigenère Ciphers in different languages.\n\nI developed custom programs to assist the decryption process using techniques such as frequency analysis and pattern recognition. This experience enhanced my problem-solving skills, and strengthened my knowledge of cryptography and decryption.",
         image: DCSImage01,
         githubLink: "https://github.com/sg9372/CBC",
    },
    {
        id: 3,
        title: "CS50 Projects",
        description: "While working through the CS50 course in the summer of 2023, I applied my skills in a variety of programming langauges to build several small projects whilst completing the course.\n\nThese projects allowed me to develop my skills in Python and C#, and allowed me to try my hand in C for the first time. Completing the course and it's projects allowed me to realize my passion for programming, and my enjoyment of the course was a large part of my reason for transitioning to software engineering.",
        image: cs50Image01,
    },
    {
        id: 4,
        title: "This Website",
        description: "This website was generated in order for me to practice my HTML, CSS and Javascript skills, whilst giving me experience learning a new tool, React.\n\n",
        image: websiteImage01,
    },
    // Add more projects here
    ];

export {projects, about};