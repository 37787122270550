import React from 'react';
import ProjectSection from '../ProjectSection/ProjectSection';
import './AllProjects.css';
import {projects} from '../../../personal_projects';

const AllProjects = () => (
    <div className="projects-container">
      {projects.map((project, index) => (
        <ProjectSection
          key={project.id}
          title={project.title}
          description={project.description}
          image={project.image}
          githubLink={project.githubLink}  // Pass the GitHub link to ProjectSection
          flipped={index % 2 !== 0} // Flip layout for odd-indexed projects
        />
      ))}
    </div>
);

export default AllProjects;