import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeContext } from './contexts/theme';

import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Introduction from './components/Introduction/Introduction';
import Experience from './components/Experience/Experience';
import Education from './components/Education/Education';

import PersonalProjectsAbout from './components/_ProjectPage/About/About';
import PersonalProjects from './components/_ProjectPage/AllProjects/AllProjects';
import PersonalProjectsHeader from './components/_ProjectPage/Header/Header';

import UniversityProjectsAbout from './components/_UniProjects/About/About';
import UniversityProjects from './components/_UniProjects/AllProjects/AllProjects';
import UniversityProjectsHeader from './components/_UniProjects/Header/Header';

import './App.css';

// Helper function to render different headers based on the route
const RenderHeader = () => {
  const location = useLocation();

  if (location.pathname === '/personalprojects') {
    return <PersonalProjectsHeader />;
  }
  if (location.pathname === '/universityprojects') {
    return <UniversityProjectsHeader />;
  }

  return <Header />;
};

const Home = () => (
  <div>
    <About />
    <Introduction />
    <Skills />
    <Projects />
    <Experience />
    <Education />
    <Contact />
  </div>
);

const PersonalProjectsPage = () => (
  <div>
    <PersonalProjectsAbout />
    <PersonalProjects />
    <Contact />
  </div>
);

const UniversityProjectsPage = () => (
  <div>
    <UniversityProjectsAbout />
    <UniversityProjects />
    <Contact />
  </div>
);

const App = () => {
  const themeContext = useContext(ThemeContext);
  const themeName = themeContext ? themeContext.themeName : 'light'; // Default to 'light'

  return (
    <Router>
      <div id='top' className={`${themeName} app`}>
        {/* Conditionally render header */}
        <RenderHeader />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/personalprojects" element={<PersonalProjectsPage />} />
            <Route path="/universityprojects" element={<UniversityProjectsPage />} />
          </Routes>
        </main>
        <ScrollToTop />
        <Footer />
      </div>
    </Router>
  );
};

export default App;