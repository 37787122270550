import uniqid from 'uniqid'
import { skills } from '../../portfolio'
import './Skills.css'

const Skills = () => {
  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'>
      <h2 className='section__title'>Skills</h2>

      <div>
        <p className='skills__introduction'>
          Technical and soft skills, obtained through both education and work experience. 
        </p>
      </div>
      
      <ul className='skills__list'>
        {skills.map((skill) => (
          <li key={uniqid()} className='skills__list-item btn btn--plain'>
            {skill.skill_name}
            <span className='skill-description'>{skill.skill_description}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Skills
