import './Introduction.css'
import { introduction } from '../../portfolio'

const Introduction = () => {
    if (!introduction) return null;

    const{description} = introduction
    
    return (
    <div className='section'>
        <h2 className='section__title'>Introduction</h2>
                <p className='description' >
                    {description}
                </p>  
    </div>
    );
};

export default Introduction;