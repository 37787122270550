import React from 'react';
import './ProjectSection.css';

const ProjectSection = ({ title, description, image, flipped }) => (
    <div className={`project-section ${flipped ? 'flipped' : ''}`}>
      <div className="project-text">
        <h2 className='project-title'>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="project-image">
        <img src={image} alt={title} />
      </div>
    </div>
  );

export default ProjectSection;