import HeatTransferGUIImage01 from "./project_images/HeatTransferGUI_image_01.png"
import UnderwaterDroneImage02 from "./project_images/UnderwaterDrone_Image_02.png"
import DataPresentationImage01 from "./project_images/DataPresentation_Image_01.png"
import ALevelCoursework01 from "./project_images/ALevelCoursework_Image_01.png"

const about = {
    // all the properties are optional - can be left empty or deleted
    title: 'Samuel Graham',
    role: 'University Projects',
    description:
        'Projects from my time studying at the University of Bath.\nMy University projects have been primarily developed in MATLAB, due to the Bath University Mechanical Engineering course, however where possible I have used Python to practice my skills.',
    resume: './resume',
    social: {
        linkedin: 'https://www.linkedin.com/in/samuel-graham-uob/',
        github: 'https://github.com/sg9372',
    },
    }

const projects = [
    {
        id: 1,
        title: "Spacecraft Heat Transfer",
        description: 'For this project, I worked in a team to simulate heat conduction through spacecraft heat shield tiles, implementing various numerical methods, including forward and backward differencing, Crank-Nicolson, and Dufort-Frankel methods. The program determines the optimal tile thickness to effectively insulate the spacecraft during atmospheric re-entry.\n\nTo enhance the usability of the simulation, I personally developed a MATLAB-based GUI that allows users to input custom parameters, visualize multiple plots, and choose different sections of the spacecraft to model. The GUI made the simulation accessible to non-experts and provided real-time feedback on the effects of varying tile thickness and other parameters.\n\nThis project highlighted my ability to apply numerical methods and software development to solve real-world, complex mathematical modelling challenges, and resulted in a grade of a first.',
        image: HeatTransferGUIImage01
    },
    {
        id: 2,
        title: "Underwater Drone Trajectory",
        description: "In the development of this project, I modelled an underwater drone using MATLAB. Differential equations were derrived to describe the drone's motion, solved using a 4th order Runge-Kutta method, and subsequently the shooting method was applied to calculate the buoyancy and internal volume change needed to pass through a specified target point. The model incorporated real-world factors such as the time needed to account for volume changes and the drone's angle of attack, significantly impacting the results.\n\nThis project highlighted my skills in numerical modeling and optimizing control strategies for autonomous systems. The project also displayed my ability to handle dynamic data sets and deliver on a program requirement, such as making the drone pass through a specific inputted point. This grade rewarded for this project was also a first.",
        image: UnderwaterDroneImage02,
    },
    {
        id: 3,
        title: "Experimental Data Analysis",
        description: "Throughout my degree I have conducted many experiments, for which I have had to analyse, manipulate and present several data sets, and deduct trends from collected data. A specific example of which was during an aerofoil lab experiment to obtain lift coefficients for different angles of attack for a NACA 2415 aerofoil with and without a lead-edge slat.\n\nThe data analysis clearly demonstrates the stall angle of the aerofoil, which in real-world applications would then need to be avoided due to the low force applied in the direction perpendicular to flow during stalling. .",
        image: DataPresentationImage01,
    },
    {
        id: 4,
        title: "A-Level Computer Science Coursework",
        description: "During my A-Levels, for my Computer Science coursework, I undertook the development of a revision app using C# and Visual Studio. This app featured student and teacher account management, class organization, and a sophisticated cue card system using spaced repetition and active recall principles for increased learning efficiency.\n\nThe project development lasted several months, including planning, development, and detailed documentation. The app resulted in an A* grade for the project, and for my Computer Science A-Level as a whole.",
        image: ALevelCoursework01,
    },
    ];

export {projects, about};