import React from 'react';
import uniqid from 'uniqid';
import GitHubIcon from '@material-ui/icons/GitHub';
import LaunchIcon from '@material-ui/icons/Launch';
import './ProjectContainer.css';

const ProjectContainer = ({ project }) => {
  const handleClick = () => {
    if (project.link) {
      window.location.href = project.link;
    }
  };

  return (
    <div
      className='project'
      onClick={handleClick}
      role="button"        // Add role attribute
      tabIndex={0}         // Make it focusable
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleClick();
        }
      }} // Allow activation with keyboard
    >
      <h3>{project.name}</h3>

      <p className='project__description'>{project.description}</p>
      {project.stack && (
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      )}

      {project.sourceCode && (
        <a
          href={project.sourceCode}
          aria-label='source code'
          className='link link--icon'
        >
          <GitHubIcon />
        </a>
      )}

      {project.livePreview && (
        <a
          href={project.livePreview}
          aria-label='live preview'
          className='link link--icon'
        >
          <LaunchIcon />
        </a>
      )}
    </div>
  );
};

export default ProjectContainer;
