import { header } from '../../../portfolio';
import Navbar from '../Navbar/Navbar';
import './Header.css';

const Header = () => {
  const { homepage, title } = header;

  return (
    <header className='header center'>
      <ul className='header__list'>
        
        <li className='header__list-item'>
          <h3>
            {homepage ? (
              <a href={homepage} className='link'>
                {title}
              </a>
            ) : (
              title
            )}
          </h3>
        </li>

        <li className='header__list-item'>
          <a href='/personalprojects' className='link header__projects-link link-nav'>
            personal projects
          </a>
        </li>

        <li className='header__list-item'>
          <a href='/universityprojects' className='link header__projects-link link-nav'>
            university projects
          </a>
        </li>

      </ul>
      <Navbar />
    </header>
  );
};

export default Header;
