import React from 'react';
import './ProjectSection.css';
import GitHubIcon from '@material-ui/icons/GitHub';

const ProjectSection = ({ title, description, image, githubLink, flipped }) => (
  <div className={`project-section ${flipped ? 'flipped' : ''}`}>
    <div className="project-text">
      <div className="project-header">
        <h2 className='project-title'>{title}</h2>
        {githubLink && (
              <a
                href={githubLink}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}
      </div>
      <p className='project-description'>{description}</p>
    </div>
    <div className="project-image">
      <img src={image} alt={title} />
    </div>
  </div>
);

export default ProjectSection;
