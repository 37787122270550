import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './EducationContainer.css'

const EducationContainer = ({ education }) => (
  <div className='education'>
    <h3>{education.name}</h3>

    <p className='education__description'>{education.description}</p>
    {education.stack && (
      <ul className='education__stack'>
        {education.stack.map((item) => (
          <li key={uniqid()} className='education__stack-item'>
            {item}
          </li>
        ))}
      </ul>
    )}

    {education.sourceCode && (
      <a
        href={education.sourceCode}
        aria-label='source code'
        className='link link--icon'
      >
        <GitHubIcon />
      </a>
    )}

    {education.livePreview && (
      <a
        href={education.livePreview}
        aria-label='live preview'
        className='link link--icon'
      >
        <LaunchIcon />
      </a>
    )}
  </div>
)

export default EducationContainer
